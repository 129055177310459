import React, { Component, Suspense } from 'react';
import Layout from './components/Layout/';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from 'react-router-dom';
import './assets/css/materialdesignicons.min.css';
import './Apps.scss';
import './assets/css/colors/default.css';
import history from './history';
import routes from './routes';

function withLayout(WrappedComponent, hasDarkTopBar) {
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <>
        <Router history={history}>
          <Suspense fallback={this.Loader()}>
            <Switch>
              <Route
                path={'/'}
                exact
                component={withLayout(
                  routes[28].component,
                  routes[28].isTopbarDark,
                )}
              />
              <Route
                path={'/services/dmp'}
                exact
                component={withLayout(
                  routes[93].component,
                  routes[93].isTopbarDark,
                )}
              />
              <Route
                path={'/services/ssp'}
                exact
                component={withLayout(
                  routes[94].component,
                  routes[94].isTopbarDark,
                )}
              />
              <Route
                path={'/services/dco'}
                exact
                component={withLayout(
                  routes[95].component,
                  routes[95].isTopbarDark,
                )}
              />
            </Switch>
          </Suspense>
        </Router>
      </>
    );
  }
}

export default withRouter(App);
